.App {
  text-align: center;
  padding: 20px;
}

.app-logo {
  height: 6vh; /* Adjust the logo height to fit within the header */
  position: absolute; /* Position it absolutely within the header */
  left: 10px; /* Distance from the left edge of the header */
  top: 50%; /* Start at the vertical center */
  transform: translateY(-50%); /* Shift it up by half its height to truly center it */
  margin-right: 10px; /* Adds some space between the logo and the title */
  margin-left: 10px; /* Adds some space from the left edge of the header */
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #f0f0f0; /* Much lighter background color */
  padding: 10px 20px; /* Adjust padding as needed */
  display: flex; /* Use flexbox for layout */
  align-items: center; /* Vertically center items */
  justify-content: center; /* Horizontally center items */
  height: 10vh; /* Adjust the height of the header */
  color: black; /* Adjust text color for better contrast with lighter background */
  position: relative; /* Makes it a positioning context */
}

.App-link {
  color: #61dafb;
}

.element-wrapper {
  margin-bottom: 20px;
}

.roosevelt-image {
  max-width: 100%;
  height: auto;
}

.image-caption {
  color: #666;
  font-style: italic;
}

.app-title {
  font-size: calc(10px + 1vmin); /* Adjust the font size as needed */
  color: #282c34; /* Adjust text color for better visibility on lighter background */
}

.record-btn {
  padding: 10px 20px; /* Adjust the padding as needed */
  /* Additional styling for the button can be added here */
  cursor: pointer; /* Changes the cursor to a pointer when hovering over the button */
  background-color: #007bff; /* Example background color */
  color: white; /* Text color */
  border: none; /* Removes the border */
  border-radius: 5px; /* Rounds the corners of the button */
  margin: 20px; /* Adds some space around the button */
  font-size: 16px; /* Adjust the font size as needed */
  transition: background-color 0.3s; /* Smooth transition for background color change */
  padding-bottom: 10px;

}

.record-btn:hover {
  background-color: #0056b3; /* Darker shade when hovered for visual feedback */
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
.title-container {
  flex-grow: 1; /* Take up the remaining space */
  display: flex; /* Use flexbox for layout */
  justify-content: center; /* Horizontally center content */
  align-items: center; /* This is redundant here but included for clarity */
}
@media (max-width: 768px) {
  .App-header {
    padding: 5px 10px; /* Reduce padding on smaller screens */
    height: auto; /* Let the header size adjust based on its content */
  }

  .app-logo {
    height: 6vh; /* Reduce the logo size on smaller screens */
    left: 10px; /* Reduce the space from the left edge of the header */
    top: 50%;
    transform: translateY(-50%);
  }

  .app-title {
    font-size: calc(8px + 2vmin); /* Reduce the font size on smaller screens */
  }

  .title-container {
    justify-content: center; /* Ensure the title is centered horizontally */
    width: 100%; /* Take the full width to allow proper centering */
    padding-left: 60px; /* Add padding to prevent text from overlapping the logo */
  }
}

@keyframes speakingPulse {
  0%, 100% {
    box-shadow: 0 0 0 0 rgba(0, 0, 128, 0.7);
  }
  25% {
    box-shadow: 0 0 0 10px rgba(0, 0, 128, 0.5);
  }
  50% {
    box-shadow: 0 0 0 15px rgba(0, 0, 128, 0.3);
  }
  75% {
    box-shadow: 0 0 0 10px rgba(0, 0, 128, 0.5);
  }
}

.speaking {
  animation: speakingPulse 1s infinite;
}

.header-btn {
  margin-left: auto; /* Aligns the button to the right of the header */
  padding: 8px 16px;
  font-size: 16px;
  color: black; /* Set text color to black */
  background-color: #d3d3d3; /* Slightly darker gray than the header */
  border: none;
  border-radius: 4px;
  cursor: pointer;
  transition: background-color 0.3s; /* Smooth transition for background color change */
}

.header-btn:hover {
  background-color: #a9a9a9; /* Darker gray on hover */
}